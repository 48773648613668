import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { deleteProduct, getAllCategories, getAllProducts, updateProductSelected } from '../../actions/productsActions';
import { FormControl, InputGroup, Table } from 'react-bootstrap';
import { AddUpdateProduct } from './components/AddUpdateProduct';
import { AddUpdatePhoto } from './components/AddUpdatePhoto';
import { SetCategory } from './components/SetCategory';
import { Swal } from 'sweetalert2';

export const ProductosAdmin = () => {

  const [ productsFilter, setProductsFilter ] = useState([]);
  const [ sortUp, setSortUp] = useState(true);

  const dispatch = useDispatch();

  const { products } = useSelector( state => state.products);
  const { isLoading } = useSelector( state => state.ui);  

  const [showAddUpdate, setShowAddUpdate] = useState(false);
  const [showSetCategory, setShowSetCategory] = useState(false);
  const [showAddUpdatePhoto, setShowAddUpdatePhoto] = useState(false);

  const handleClose = () => { 
    dispatch( updateProductSelected(null) );
    setShowAddUpdate(false); 
  }

  const handleCategoryClose = () => {
    setShowSetCategory(false);
  }

  const handleClosePhoto = () => { 
    dispatch( updateProductSelected(null) );
    setShowAddUpdatePhoto(false); 
  }

  const handleShow = () => setShowAddUpdate(true);
  const handleSetCategory = ( product ) => {
    dispatch( updateProductSelected(product) );
    setShowSetCategory(true);
  }

  useEffect(  ( ) => {
    dispatch( getAllProducts() );
    dispatch( getAllCategories() );
  }, [ dispatch ] );

  useEffect( ( ) => {
    setProductsFilter( products );
  } , [ products ] );

  const handleEdit = ( product ) => { 

    dispatch( updateProductSelected(product) );
    setShowAddUpdate(true);

  } 

  const handleEditPhoto = ( product ) => {
    dispatch( updateProductSelected(product) );
    setShowAddUpdatePhoto(true);
  }

const filterList = ({ target }) => {
  const { value } = target;
  if( value?.length > 0 ) {
      setProductsFilter( products.filter( p => p.name.toLowerCase().includes(value.toLowerCase()) ) );
  } else {
      setProductsFilter( products );
  }
}

const handleDeleteProduct = ( product ) => {

  Swal.fire({
    title: "¿Estás seguro de eliminar el producto?",
    showDenyButton: true,
    showCancelButton: true,
    confirmButtonText: "Si, estoy seguro",
    denyButtonText: `No, volver`
  }).then((result) => {
    if (result.isConfirmed) {
      dispatch( deleteProduct( product ));  
    }
  });

}

const handleSort = () => {
    setSortUp(!sortUp);
    if( !sortUp ){
        productsFilter.sort((a, b) => {
            if (a.name > b.name) {
                return -1;
            }
            return 1 
        });
    } else {
        productsFilter.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            return 1
        });
    }        
}

  return (
    <>
      <h1 className="usuarios_title">Listado de Productos</h1>
        <hr/>
        <div className="d-flex justify-content-center">
            <div className='w75'>
                <InputGroup className="mb-3">
                    <InputGroup.Text id="basic-addon1"><i className='fas fa-search'></i></InputGroup.Text>
                    <FormControl
                        placeholder="Buscar...."
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                        onChange={ filterList }
                    />
                </InputGroup>
            </div>
        </div>
        <hr/>

        <div className='d-flex flex-row'>
          <div className="components__small-box pointer ml-3 d-flex justify-content-center align-items-center me-3" 
               onClick={(!isLoading) ? handleShow : null}
               data-bs-toggle="tooltip" data-bs-placement="top" title="Nuevo Producto">
            <i className="fa-solid fa-plus fa-3x"></i>
          </div>
        </div>

        <hr/>

        <Table striped bordered={false} hover>
            <thead>
                <tr className="components__table-header">
                    <th style = {{ width: "50%"}}>
                      Nombre y detalle
                      {
                            ( sortUp )
                            ? <i className='fas fa-arrow-up ms-2 pointer text-end' onClick={ handleSort }></i>
                            : <i className='fas fa-arrow-down ms-2 pointer text-end' onClick={ handleSort }></i>
                        }   
                    </th>
                    <th>Prioridad</th>
                    <th>Precio 1</th>
                    <th>Precio 2</th>
                    <th>Precio 3</th>
                    <th>Precio 4</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                { 
                    (productsFilter?.length > 0) 
                        ? productsFilter.map( (p, i) => 
                        (<tr key={i}>
                            <td>
                              <h6>{ p.name }</h6>
                              <p>{ p.description }</p>
                            </td>
                            <td>
                              <div className="row">
                                <div className="col-6 text-end">{ p.priority }</div>
                                <div className="col-6 text-start"><i className='fas fa-star'></i></div>                                                          
                              </div>
                            </td>
                            <td>{(p.isDolar)? 'USD ' : '$'} { p.price1?.toLocaleString('es-Ar') }</td>
                            <td>{(p.isDolar)? 'USD ' : '$'} { p.price2?.toLocaleString('es-Ar') }</td>
                            <td>{(p.isDolar)? 'USD ' : '$'} { p.price3?.toLocaleString('es-Ar') }</td>
                            <td>{(p.isDolar)? 'USD ' : '$'} { p.price4?.toLocaleString('es-Ar') }</td>                        
                            <td>
                              <div 
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Producto"
                                className="components__boton-action components__boton-action-edit"
                                onClick = { () => handleEdit( p )}>
                                  <i className="fas fa-pen text-inverse"></i>
                              </div>
                              <div 
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Agregar Foto"
                                className="components__boton-action components__boton-action-show"
                                onClick = { () => handleEditPhoto( p )}>
                                  <i className="fa-solid fa-image"></i>
                              </div>
                              <div 
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Editar Categorías del producto"
                                className="components__boton-action components__boton-action-show"
                                onClick = { () => handleSetCategory( p )}>
                                  <i className="fas fa-clone"></i>
                              </div>
                              <div 
                                data-bs-toggle="tooltip" data-bs-placement="top" title="Borrar producto"
                                className="components__boton-action components__boton-action-show"
                                onClick = { () => handleDeleteProduct( p )}>
                                  <i className="fas fa-trash"></i>
                              </div>
                            </td>    
                        </tr>))
                        : (<></>)
                }
            </tbody>
        </Table>
        <AddUpdateProduct show={showAddUpdate} onHide={handleClose} />
        <SetCategory show={showSetCategory} onHide={handleCategoryClose} />
        <AddUpdatePhoto show={showAddUpdatePhoto} onHide={handleClosePhoto} />
    </>
  )
}
